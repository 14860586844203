import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Dimension, FamilyDimensionAssociation, ProductHeaders, Scope, ScopeType } from '../../../data/model/DataModels';
import styles from './ScopeCard2.module.scss';
import { confirmPopup } from 'primereact/confirmpopup';
import { FieldIdentifiers, formValidation } from '../../../utils/validationUtil';
import { dimensionIncludeValues } from "../../utils/dimensionData";
import ShowListTypeDimension from './ShowListTypeDimension/ShowListTypeDimension.lazy';
import ShowTreeTypeDimension from './ShowTreeTypeDimension/ShowTreeTypeDimension';
import DimensionsDialogBox from '../../DimensionsDialogBox/DimensionsDialogBox';
import { Tooltip } from 'primereact/tooltip';
import { useGetDimensionTypesQuery, useGetTreeTypeDimensionDataQuery } from '../../../data/api/RefDataApi';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import { ToasterContext } from '../../../features/AppLayoutView/AppLayoutView';
import { buildDimensionsWithAllValues, getDimensionIcon, isGeoCountryTypeDimension } from '../ScopeHelper';
import RegionSelection from '../../../features/RegionSelection/RegionSelection';
import ShowMultiTreeTypeDimension from '../../../features/ShowMultiTreeTypeDimension/ShowMultiTreeTypeDimension';
import { Dialog } from "primereact/dialog";

export interface ScopeCard2Props {
  scope: Scope;
  editMode: boolean;
  saveScope: Function;
  deleteScope?: Function;
  setScopeValidity: Function;
  defaultDimensions: FamilyDimensionAssociation[];
  scopeIndex: number;
  editDimension?: Function;
  isAuthorisedToEdit?: boolean;
  originDestinationConfigurationFlag?: boolean;
  locationConfigurationFlag?: boolean;
  headerInfo?: ProductHeaders
}

const ScopeCard2: FC<ScopeCard2Props> = ({ scope, editMode, saveScope, deleteScope, setScopeValidity, defaultDimensions, scopeIndex, editDimension, isAuthorisedToEdit = false, originDestinationConfigurationFlag, locationConfigurationFlag, headerInfo }) => {
  const { data: allDimensionsData = [], error: errorAllDimensionsData, isLoading: isLoadingAllDimensionsData } = useGetDimensionTypesQuery();
  const { data: regions = [], isLoading: isLoadingRegions } = useGetTreeTypeDimensionDataQuery("imports");

  const [dimensions, setDimensions] = useState([] as Dimension[]);
  const [scopeDimensionsValidity, setscopeDimensionsValidity] = useState(false);
  const [clickedDimension, setClickedDimension] = useState({} as Dimension);
  const [showDimensionsDialog, setShowDimensionsDialog] = useState(false);
  const [selectedSublist, setSelectedSublist] = useState("APA");
  const [showConfirmDeleteDimension, setShowConfirmDeleteDimension] = useState(false);
  const [dimensionToBeDeleted, setDimensionToBeDeleted] = useState({} as Dimension);

  useEffect(() => {
    const dimensions = [...new Set(scope.dimensions.map(dimension => dimension.type))];
    if (dimensions.includes("locations") && (dimensions.includes("imports") || dimensions.includes("exports"))) {
      setScopeValidity && setScopeValidity(false, scope.code);
      return setscopeDimensionsValidity(false);
    }
    setScopeValidity && setScopeValidity(true, scope.code);
    return setscopeDimensionsValidity(true);
  }, [scope.dimensions.length]);

  const toaster = useContext(ToasterContext);

  const checkTitleValidity = (value: string, fieldIdentifier: FieldIdentifiers, scopeCode: string) => {
    const validityResult = formValidation(value, fieldIdentifier, true, "Enter applicability name");
    if (validityResult !== "") {
      setScopeValidity && setScopeValidity(false, scopeCode + "-title");
    } else {
      setScopeValidity && setScopeValidity(true, scopeCode + "-title");
    }
    return validityResult;
  }

  useEffect(() => {
    if (scope?.dimensions.length > 0) {
      // Todo: revisit
      const dimensionTypes = allDimensionsData.map(dimension => dimension.code);
      const dimensionsData = scope?.dimensions?.slice().sort((d1, d2) => dimensionTypes.indexOf(d1.type) - dimensionTypes.indexOf(d2.type));
      setDimensions(dimensionsData);
      const updatedClickedDimension = dimensionsData?.find(dimension => dimension.type == clickedDimension?.type);
      if (updatedClickedDimension) {
        setClickedDimension(updatedClickedDimension);
      } else {
        setClickedDimension(dimensionsData[0])
      }
    }
  }, [scope]);

  if (errorAllDimensionsData) {
    toaster.showToast('error', 'Failed to fetch dimensions');
  }

  const getTypeOfDimension = (code: string) => {
    const dimensionDefinition = allDimensionsData?.find(dimension => dimension.code == code);
    if (dimensionDefinition) {
      return dimensionDefinition.type;
    }
    return "list";
  }
  const getDimensionDefinition = (code: string) => {
    const dimensionDefinition = allDimensionsData?.find(dimension => dimension.code == code);
    if (dimensionDefinition) {
      return dimensionDefinition;
    }
    return undefined;
  }

  const deleteDimensionAndValidateForm = (dimensionToDelete: Dimension) => {
    setShowConfirmDeleteDimension(false);
    const updatedDimensions = dimensions.filter(dimension => dimension.type !== dimensionToDelete.type);
    setDimensions(updatedDimensions);
    saveScope({ ...scope, dimensions: updatedDimensions })
    setScopeValidity && setScopeValidity(true, scope.code != "" ? scope.code : scope.codeUI);
    updatedDimensions.length > 0 && setClickedDimension(updatedDimensions[0]);
    setDimensionToBeDeleted({} as Dimension);
  }

  const isDefaultDimension = (dimension: Dimension) => {
    if (defaultDimensions.length > 0) {
      const matchedDimension = defaultDimensions.filter(dim => dim.dimensionCode === dimension.type);
      return matchedDimension.length === 1 ? true : false;
    }
    else {
      return false;
    }
  }

  const deleteDimension = (e: any, dimension: Dimension) => {
    e.stopPropagation();
    setDimensionToBeDeleted(dimension);
    setShowConfirmDeleteDimension(true);
  }

  const deleteDimensionDialogFooter = () => (
    <div>
      <Button label="No, keep dimension" className={styles.rejectBtn} onClick={() => setShowConfirmDeleteDimension(false)} />
      <Button label="Yes, delete dimension" className={styles.acceptBtn} onClick={() => deleteDimensionAndValidateForm(dimensionToBeDeleted)} />
    </div>
  );

  const confirmDeleteDimensionDialog = () => {
    const selectedDimension = getDimensionDefinition(dimensionToBeDeleted.type);
    return (
      <Dialog visible={showConfirmDeleteDimension} showHeader={false} footer={deleteDimensionDialogFooter()} onHide={() => setShowConfirmDeleteDimension(false)} style={{ width: '36vw' }} data-testid="confirmDeleteDimensionDialog">
        <div className={styles.deleteDimension}>
          <div className={styles.deleteDimensionHeader}>Delete Dimension?</div>
          <div className={styles.deleteDimensionSubHeader}>Are you sure you want to delete {selectedDimension?.name} ?</div>
          <div className={styles.deleteDimensionBody}>Deleting this dimension from {scope.name} will:</div>
          <div className={styles.deleteDimensionBodyContent}>
            <div className={styles.warningList}>
              <div className={styles.warningItem}>
                <div><i className="pi pi-exclamation-circle" /></div>
                <div>Remove all the exclusions from the dimension for {scope.name}</div>
              </div>
              <div className={styles.warningItem}>
                <div><i className="pi pi-exclamation-circle" /></div>
                <div>Remove the dimension from the current scope</div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }

  const showHeader = () => {
    return (
      <div className={styles.header}>
        <div className={styles.scopeCount}>{('00' + (scopeIndex + 1)).slice(-2)}</div>
        {
          !editMode ?
            <div className={styles.scopeTitle}>
              <span data-testid="scopeNameText">
                {scope.name}
              </span>
            </div>
            :
            <>
              <div>
                <InputText value={scope?.name} placeholder="Applicability name" className={`${styles.scopeTitle} ${formValidation(scope?.name, FieldIdentifiers.APPLICABILITY_TITLE) !== "" && "p-invalid"}`}
                  onChange={(e) => saveScope({ ...scope, name: e.target.value })} data-testid="scopeNameInputText" readOnly={!isAuthorisedToEdit} />
                <div>
                  {
                    checkTitleValidity(scope?.name, FieldIdentifiers.APPLICABILITY_TITLE, scope?.code || scope?.codeUI || "")
                  }
                </div>

              </div>
              {deleteScope &&
                <div className={styles.deleteButton}>
                  {isAuthorisedToEdit && <Button icon="pi pi-trash" label="Delete" data-testid={`deleteScope-${scopeIndex}`} className="p-button-outlined p-button-danger"
                    onClick={(e) => confirmAndDeleteScope(e)} />}
                </div>}
            </>


        }
      </div>
    );
  };

  const dimensionTile = (dimension: Dimension) => {
    const dimensionDef = getDimensionDefinition(dimension.type);
    const dimensionAttribute = {
      type: dimensionDef?.code,
      name: dimensionDef?.name,
      icon: getDimensionIcon(dimension.type)
    };
    const dimensionTileClicked = (dimension: Dimension) => {
      setClickedDimension(dimension);
      if (getTypeOfDimension(dimension.type) === "multitree") {
        if (clickedDimension.type != dimension.type) {
          setSelectedSublist("APA");
        }
      } else {
        setSelectedSublist("");
      }
    }
    return (
      <div key={dimension.type}
        className={`${styles.dimensionTile} ${clickedDimension.type === dimension.type ? styles.clickedDimension : styles.defaultIconColor}`}
        onClick={() => { dimensionTileClicked(dimension) }} data-testid="dimensionTile">
        <div className={styles.dimensionIconName}>
          {dimensionAttribute?.icon}
        </div>
        <div className={`${styles.dimensionTitle} body-text-default`}>

          <div className={styles.dimensionValues}>
            <div>{dimensionAttribute?.name}</div>
            <div className={styles.dimensionInclusion}>{showSelectedDimensionValue(dimension.type)}</div>
          </div>
        </div>
        {
          editMode && isAuthorisedToEdit &&
          <div className={styles.dimensionDeleteEditIcon}>
            {/* <div className={isDefaultDimension(dimension) ? "deleteButtonTooltip" : ""}>
              <span className={`pi pi-trash ${isDefaultDimension(dimension) ? styles.deleteIconDisabled : ''}`}
                onClick={(e) => !isDefaultDimension(dimension) && deleteDimension(e, dimension)} data-testid={`${dimensionAttribute?.type}-deleteDimensionIcon`}/>
            </div> */}
            <Button icon="pi pi-trash" rounded text className={styles.iconColor} onClick={(e) => !isDefaultDimension(dimension) && deleteDimension(e, dimension)} data-testid={`${dimensionAttribute?.type}-deleteDimensionIcon`} disabled={isDeleteBtnDisabled(dimension) !== ""} tooltip={isDeleteBtnDisabled(dimension)} tooltipOptions={{ showOnDisabled: true, position: "bottom" }} />
            <Button icon="pi pi-pencil" rounded text className={styles.iconColor} onClick={() => editDimension && editDimension(dimension)} data-testid={`${dimensionAttribute?.type}-editDimensionIcon`} disabled={checkOriginDestinationRule(dimension) !== ""} tooltip={checkOriginDestinationRule(dimension)} tooltipOptions={{ showOnDisabled: true, position: "bottom" }} />
          </div>
        }

      </div>
    )
  };

  const showSelectedDimensionValue = (dimensionType: string) => {
    const values = dimensions.find(item => item.type === dimensionType)?.value;

    if (getTypeOfDimension(dimensionType) != "multitree") {
      if (values && values[0] && values[0] == dimensionIncludeValues.ALL_INCLUDED) {
        return <span> All included</span>;
      } else {
        return <span>{values?.length} included</span>;
      }
    } else {
      // for multi tree
      let numberOfLeafValues = 0;
      let isAllIncluded = true;

      values?.forEach((element: any) => {
        if (element.values[0] && element.values[0] == dimensionIncludeValues.ALL_INCLUDED) {
          let region = getRegionOptions()?.find(region => region.code === element.code);
          if (region) {
            numberOfLeafValues = numberOfLeafValues + region?.numberOfLeaves;
          }
        } else {
          isAllIncluded = false;
          numberOfLeafValues = numberOfLeafValues + element.values.length;
        }
      });
      if (isAllIncluded) {
        return <span> All included</span>
      } else {
        return <span>{numberOfLeafValues} included</span>;
      }
    }

  };

  const getSublistData = () => {
    const filteredCities: any = regions?.find(city => city.code === selectedSublist);
    if (filteredCities) {
      return filteredCities?.children;
    }
    return [];
  }

  const showDimensionValues = (type: ScopeType) => {
    // const updateClickedDimension = dimensions.find(dimension => dimension.type == clickedDimension.type);
    const dimensionType = getTypeOfDimension(clickedDimension.type);
    if (dimensionType && clickedDimension.type) {
      switch (dimensionType) {
        case "list":
          return <ShowListTypeDimension dimension={clickedDimension} type={type} />
        case "tree":
          return <ShowTreeTypeDimension dimension={clickedDimension} type={type} />
        case "multitree":
          return <ShowMultiTreeTypeDimension nodes={getSublistData()} dimensionValues={clickedDimension.value.find((region: any) => region.code === selectedSublist)?.values} type={type} selectedRegion={selectedSublist} />

      }
    }
    return <></>;
  };

  const constructDimensionTiles = () => {
    return (
      <>
        {
          dimensions?.filter(dimension => !isGeoCountryTypeDimension(dimension.type)).map(dimension => dimensionTile(dimension))
        }
      </>
    )
  };

  const isDeleteBtnDisabled = (dimension: Dimension): string => {
    if (isDefaultDimension(dimension))
      return "You cannot remove a default dimension";
    else
      return checkOriginDestinationRuleOnDelete(dimension);
  }

  const checkOriginDestinationRule = useMemo(() => {
    return (dimension: Dimension): string => {
      let validationMsg = "";
      if (headerInfo && headerInfo?.direction?.length! > 0) {
        const directionHeaderValues: string[] = headerInfo?.direction?.map(header => header.code)!;
        if ((directionHeaderValues?.includes("origin") && !directionHeaderValues.includes("destination")) && dimension.type === "imports") {
          validationMsg = "This is an origin based product,\nTo set applicability rules for destination locations,\nPlease change direction values in header section";
        }
        else if ((directionHeaderValues.includes("destination") && !directionHeaderValues.includes("origin")) && dimension.type === "exports") {
          validationMsg = "This is a destination based product,\nTo set applicability rules for origin locations,\nPlease change direction values in header section";
        }
      }
      return validationMsg;
    }
  }, [headerInfo])

  const checkOriginDestinationRuleOnDelete = useMemo(() => {
    return (dimension: Dimension): string => {
      let validationMsg = "";
      if (headerInfo && headerInfo?.direction?.length! > 0) {
        const directionHeaderValues: string[] = headerInfo?.direction?.map(header => header.code)!;
        if (directionHeaderValues?.includes("origin") && dimension.type === "exports") {
          validationMsg = "This is an origin based product,\nTo remove applicability rules for origin locations,\nPlease change direction values in header section";
        }
        else if (directionHeaderValues?.includes("destination") && dimension.type === "imports") {
          validationMsg = "This is a destination based product,\nTo remove applicability rules for destination locations,\nPlease change direction values in header section";
        }
      }
      return validationMsg;
    }
  }, [headerInfo])



  const confirmAndDeleteScope = (event: any) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteScopeAndValidateForm(),
    });
  };

  const deleteScopeAndValidateForm = () => {
    deleteScope && deleteScope(scope.code, scope?.codeUI);
    setScopeValidity && setScopeValidity(true, scope.code != "" ? scope.code : scope.codeUI);
  }


  const displayDimensionDialogBox = () => {
    setShowDimensionsDialog(true);
  }

  const addDimension = (dimension: string[]) => {
    setShowDimensionsDialog(false);
    const previouslySavedDimensions = scope?.dimensions?.map(dimension => dimension.type);
    const newDimensions: Dimension[] = buildDimensionsWithAllValues(dimension.filter(dimensionCode => !previouslySavedDimensions.includes(dimensionCode)), allDimensionsData, regions);
    saveScope({ ...scope, dimensions: [...dimensions, ...newDimensions] });
  }

  const closeDimensionDilogBox = () => {
    setShowDimensionsDialog(false);
  }

  const getDescriptionText = () => {
    return (
      <div>Please add dimensions you need to define in <b>{scope.name}</b>.</div>
    )
  }

  const defaultDimensionCodes = defaultDimensions?.map(dimension => dimension.dimensionCode);
  const productDimensionCodes = scope?.dimensions?.filter(dimension => !defaultDimensionCodes.includes(dimension.type)).map(dimension => dimension.type);
  const savedDimensions = scope?.dimensions?.map(dimension => dimension.type);

  const disableAddDimensionNameCheck = scope?.name === "";
  const disableAddDimensionDimensionCheck = scope?.dimensions?.length === allDimensionsData.length;

  const displayDeleteButtonToolTip = () => {
    return (<Tooltip target=".deleteButtonTooltip" className="general-tooltip" showEvent='click' autoHide={false} position="left" disabled={false}>
      <div className="p-d-flex p-flex-column">You cannot remove a default dimension</div>
    </Tooltip>);
  }

  const getRegionOptions = (): { name: string, code: string, numberOfLeaves: number }[] => {
    if (!regions) {
      return [] as { name: string, code: string, numberOfLeaves: number }[];
    }
    return regions.map(region => {
      let numberOfLeaves = 0;
      region.children?.forEach(area =>
        area.children?.forEach(country => numberOfLeaves = numberOfLeaves + country.children.length)
      )
      return { name: region.name, code: region.code, numberOfLeaves }
    });
  }

  const regionSelectionButtons = () => {
    const isMultiTreeTypeDimension = getTypeOfDimension(clickedDimension.type) === "multitree";
    if (!isMultiTreeTypeDimension) {
      return <></>
    }
    return (
      <RegionSelection regionOptions={getRegionOptions()} selectedValues={clickedDimension.value} selectedRegionCode={selectedSublist} onRegionSelection={(regionCode: string) => setSelectedSublist(regionCode)} />
    )
  }

  const calcMaxHeightOfValueDisplay = (dimensionCode: string) => {
    const calcHeight = window.innerWidth < 1724 ? "calc(100% - 9rem)" : "calc(100% - 5rem)";
    return getTypeOfDimension(dimensionCode) != "multitree" ? "100%" : calcHeight;
  }

  return (
    <div className={styles.ScopeCard2} data-testid="ScopeCard2">
      {(isLoadingAllDimensionsData || isLoadingRegions) && <SpinnerComponent />}
      {
        !scopeDimensionsValidity &&
        <div style={{ fontSize: "smaller", color: "red", margin: "0.25rem 0" }}>The scope is invalid as it contains both locations and origins/destinations</div>
      }
      {showHeader()}
      <div className={styles.scopeContent} style={{ maxHeight: `calc(${editMode ? dimensions.length + 1 : dimensions.length}*5rem)`, minHeight: `${dimensions.length < 7 ? "35rem" : "100%"}` }}>
        <div className={styles.dimensions}>
          {
            constructDimensionTiles()
          }
          {editMode && isAuthorisedToEdit &&
            <div className={`${styles.addDimensionButton} addDimensionButtonTooltip-${scopeIndex}`}>
              <Button
                label="Add Dimension"
                icon="pi pi-plus"
                className={`p-button-outlined p-button-secondary ${!(disableAddDimensionNameCheck || disableAddDimensionDimensionCheck) && styles.addButtonActive}`}
                onClick={() => displayDimensionDialogBox()}
                disabled={disableAddDimensionNameCheck || disableAddDimensionDimensionCheck}
              />
              <Tooltip disabled={!(disableAddDimensionNameCheck || disableAddDimensionDimensionCheck)} target={`.addDimensionButtonTooltip-${scopeIndex}`} position="top">
                {disableAddDimensionNameCheck ? "Please add a scope name" : disableAddDimensionDimensionCheck ? "All Dimensions are added" : ""}
              </Tooltip>
            </div>
          }

        </div>
        <div className={styles.dimensionSublistSelectionAndValues}>
          {regionSelectionButtons()}
          <div className={styles.includedExcludedValuesArea} style={{ maxHeight: calcMaxHeightOfValueDisplay(clickedDimension.type), minHeight: calcMaxHeightOfValueDisplay(clickedDimension.type) }}>
            <div className={`${styles.showSelection}`}>
              <div className={`${'body-text-default'} ${styles.selectionHeader}`}>
                <span>Included</span>
              </div>
              <div className={styles.wrapperDiv}>
                <div className={styles.selectionContent}>
                  {showDimensionValues("inclusion")}
                </div>
              </div>

            </div>
            <div className={`${styles.showSelection}`}>
              <div className={`${'body-text-default'} ${styles.selectionHeader}`}>
                <span>Excluded</span>
              </div>
              <div className={styles.wrapperDiv}>
                <div className={styles.selectionContent}>
                  {showDimensionValues("exclusion")}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {
        showDimensionsDialog && <DimensionsDialogBox headerName={"Add Dimensions"} descriptionText={getDescriptionText} savedDimensionsData={savedDimensions} applyDimensions={addDimension} closeDimensionDialogBox={closeDimensionDilogBox} defaultDimensionsData={defaultDimensionCodes} productDimensionsData={productDimensionCodes}
          originDestinationConfigurationFlag={originDestinationConfigurationFlag}
          locationConfigurationFlag={locationConfigurationFlag}
        />
      }
      {displayDeleteButtonToolTip()}
      {confirmDeleteDimensionDialog()}
    </div>
  )
};


export default ScopeCard2;
